<template>
  <div class="task-detail">
    <Header></Header>
    <div class="task-detail-center">
      <div class="task-detail-center-header">
        <span> <i v-for="(catname, catkey) in service.category_names" :key="catkey"> <span>{{ catname }}</span> <b> > </b> </i> {{service.subject}} </span>
      </div>
      <div class="task-detail-center-border">
        <div class="task-detail-center-border-left">
          <!-- 图片 -->
          <div class="task-detail-center-border-left-imgBorder">
            <!-- 主图 -->
            <div class="task-center-bigimg">
              <img :src="service.thumb" alt="" />
            </div>

            <div class="task-center-smallimg">
              <div class="task-center-smallimg-center">
                <img v-for="( imgItem, imgIndex ) in service.more_thumb_array" :key="imgIndex" :src="imgItem" alt="" />
              </div>
            </div>
          </div>
          <!-- 内容介绍 -->
          <div class="task-detail-center-border-left-fontBorder">
            <div class="task-detail-center-border-left-fontBorder-header">
              <h2> {{ service.subject }} </h2>
              <div class="task-detail-heart">
                <!-- 缺少心形icon -->
              </div>
            </div>

            <div class="task-detail-center-border-left-fontBorder-header-center">
              <div class="task-detail-center-project-detail">
                <div class="task-project-detail-name">
                  <strong>项目详情</strong>
                </div>
                <div class="service-content" v-html="service.content" />
              </div>
            </div>

            <div class="task-detail-center-border-left-fontBorder-bottom">
              <div
                class="task-detail-center-border-left-fontBorder-bottom-header"
              >
                <h2>服务分类</h2>
              </div>
              <div class="task-serve">
                <div class="task-serve-classify">
                  <ul>
                    <li>
                      <strong>服务：</strong>
                      <i v-for="(catname, catkey) in service.category_names" :key="catkey"> <span>{{ catname }}</span> <b> > </b> </i>
                    </li>
                    <li>
                      <strong>技能：</strong>
                       <i v-for="(skillname, skillkey) in service.skill_names" :key="skillkey"> <span>{{ skillname }}</span> &nbsp;&nbsp;</i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- 服务评价 -->
          <div class="task-detail-center-border-left-serveBorder">
            <!-- 服务头部 -->
            <div class="task-serve-header">
              <div class="task-serve-header-center">
                <div class="task-serve-header-center-left">
                  <strong>服务评价</strong
                  ><el-rate
                    v-model="value"
                    disabled
                    show-score
                    text-color="#ff9900"
                    score-template="{value}"
                  ></el-rate>
                </div>
                <div class="task-serve-header-center-right">
            
                  <div class="task-serve-header-center-right1">
                    显示 1 - {{reviewListPages.page_size}} 条评论，共 {{reviewListPages.total}} 条评论
                  </div>
                  
                  <el-pagination
                    :current-page="reviewListPages.page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="reviewListPages.page_size"
                    layout="sizes"
                    :total="reviewListPages.total"
                  />
                </div>
              </div>
            </div>
            <!-- 服务中间的内容 -->
            <div class="task-serve-center">
              <div class="task-serve-centers" v-for="( review, reIndex ) in reviewListData" :key="reIndex">
                <div class="task-serve-center-border">
                  <div class="task-serve-center-left">
                    <img :src="review.user_avatar" alt="" />
                  </div>
                  <div class="task-serve-center-right">
                    <div class="task-serve-center-right-top">
                      <div class="task-serve-center-right-top1">
                        <strong>{{review.user_name}}</strong
                        ><span>{{ review.review_time }}</span>
                      </div>
                      <div class="task-serve-center-right-top2">
                        <el-rate
                          v-model="review.score"
                          disabled
                          show-score
                          text-color="#ff9900"
                          score-template="{value}"
                        ></el-rate>
                      </div>
                    </div>
                    <div class="task-serve-center-right-bottom">
                      {{ review.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-pagination
              style="float:right;"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="reviewListPages.page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="reviewListPages.page_size"
              prev-text="上一页"
              next-text="下一页"
              layout="prev, pager, next,->,total"
              :total="reviewListPages.total"
            />
          </div>
          <!-- 底部的为您推荐 -->
          <div class="task-serve-recommend">
            <div class="task-serve-recommend-header">
              <div class="task-serve-recommend-header-left">
                <span>为您推荐</span>
              </div>
              <div class="task-serve-recommend-header-right">
                <img src="@/icons/svg/audit/换一批16X16.svg" alt="" />
                <strong>换一批</strong>
              </div>
            </div>
            <div class="task-serve-recommend-bottom">
              <div class="task-serve-recommend-bottom-center">
                <!-- <CupSmallStart></CupSmallStart> -->
              </div>
            </div>
          </div>
        </div>
        <div class="task-detail-center-border-right">
          <div class="task-detail-center-border-right1">
            <span>分享到</span
            ><img src="@/icons/svg/login/微信24X24.svg" alt="" />
            <img src="@/icons/svg/login/微博24X24.svg" alt="" /><img
              src="@/icons/svg/login/QQ24X24.svg"
              alt=""
            />
          </div>
          <div class="task-detail-center-border-right2">
            <div class="task-detail-center-border-right2-header">
              <div class="task-detail-center-border-right2-header-photo">
                <img :src="service.user_avatar" alt="" />
              </div>
              <div class="task-detail-center-border-right2-header-font">
                <h2> {{ service.user_name }} </h2>
                <p><span> {{ service.last_login_at_format }} </span><button>留言</button></p>
              </div>
            </div>
            <div class="task-detail-center-border-right2-bottom">
              <div class="task-detail-center-border-right2-bottom1">
                <div class="task-detail-center-border-right2-bottom-left">
                  <span>时薪：</span>
                  <strong> {{ service.hourly_rate }} 元/小时</strong>
                </div>
                <div class="task-detail-center-border-right2-bottom-right">
                  <span>完成项目：</span><b>{{service.finish_task_count}}项</b>
                </div>
              </div>
              <div class="task-detail-center-border-right2-bottom2">
                <span>入驻时间：</span> <b> {{service.settled_days}} 天前</b>
              </div>
              <div class="task-detail-center-border-right2-bottom3">
                <el-rate
                  v-model="value"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}"
                >
                </el-rate>
                <b style="margin-left: 10px">( {{service.review_count_2}} 条评论)</b>
              </div>
              <div class="task-detail-center-border-right2-bottom4">
                {{ service.user_intro }}
                <button>展开</button>
              </div>
            </div>
          </div>
          <div class="task-detail-center-border-right3">
            <div class="task-detail-center-border-right3-center">
              <div class="task-detail-center-border-right3-center1">
                <div class="task-detail-center-border-right3-center1-font1">
                  服务费：{{ service.price_format }}元
                </div>
                <div class="task-detail-center-border-right3-center1-font2">
                  支付周期：2天
                </div>
              </div>
            </div>
            <div class="task-detail-center-border-right3-center-button">
              <div
                class="task-detail-center-border-right3-center-button-center"
              >
                <div class="task-detail-center-border-right3-center-button1">
                  <button>雇佣{{ service.user_name }}</button>
                </div>
                <div class="task-detail-center-border-right3-center-button2">
                  <el-button type="button" @click="dialogVisible = true"
                    >邀请到其它项目</el-button
                  >
                  <el-dialog
                    title="正在进行的招标任务"
                    :visible.sync="dialogVisible"
                    width="800px"
                    :before-close="handleClose"
                    center
                  >
                    <div class="el-dialog-checked">
                      <div class="el-dialog-first">
                        <div class="el-dialog-first-left">
                          <el-checkbox v-model="checked1"
                            >需要一名家政阿姨帮忙做晚饭</el-checkbox
                          >
                        </div>
                        <div class="el-dialog-first-center">
                          <strong>100元/时</strong>
                        </div>
                        <div class="el-dialog-first-right">
                          <b>1人参与</b>
                        </div>
                      </div>
                      <div class="el-dialog-first">
                        <div class="el-dialog-first-left">
                          <el-checkbox v-model="checked1"
                            >需要一名家政阿姨帮忙做晚饭政阿姨政阿姨帮忙做晚饭111</el-checkbox
                          >
                        </div>
                        <div class="el-dialog-first-center">
                          <strong>100元/时</strong>
                        </div>
                        <div class="el-dialog-first-right">
                          <b>1人参与</b>
                        </div>
                      </div>
                      <div class="el-dialog-first">
                        <div class="el-dialog-first-left">
                          <el-checkbox v-model="checked1"
                            >需要一名家政阿姨帮忙做晚饭帮忙做晚饭</el-checkbox
                          >
                        </div>
                        <div class="el-dialog-first-center">
                          <strong>100元/时</strong>
                        </div>
                        <div class="el-dialog-first-right">
                          <b>1人参与</b>
                        </div>
                      </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="dialogVisible = false"
                       class="el-primary" style="background:#00a2e6;font-size:14px;width:184px;height:34px;margin-right:40px">确认邀请</el-button
                      >
                      <el-button @click="dialogVisible = false" style="background:#b5b5b5;font-size:14px;width:184px;height:34px">取消</el-button>
                    </span>
                  </el-dialog>
                </div>
              </div>
            </div>
          </div>
          <div class="task-detail-center-border-right4">
            <div class="task-detail-center-border-righ4-header">
              <h2>平台认证</h2>
            </div>
            <div class="task-detail-center-border-right4-bottom">
              <ul>
                <li>
                  <img src="@/icons/svg/task/资深认证16X16.svg" alt="" />
                  <span>平台资深人才认证</span>
                </li>
                <li>
                  <img src="@/icons/svg/task/手机认证16X16.svg" alt="" />
                  <span>手机号码已验证</span>
                </li>
                <li>
                  <img src="@/icons/svg/task/邮箱认证16X16.svg" alt="" />
                  <span>电子邮箱已验证</span>
                </li>
                <li>
                  <img src="@/icons/svg/task/实名认证16X16.svg" alt="" />
                  <span>实名认证已完成</span>
                </li>
                <li>
                  <img src="@/icons/svg/task/企业认证16X16.svg" alt="" />
                  <span>企业认证已完成</span>
                </li>
                <li>
                  <img src="@/icons/svg/task/支付认证16X16.svg" alt="" />
                  <span>支付认证</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import CupSmallStart from "@/components/CupSmallStart";
import { getServiceInfo } from "@/api/service";
import { reviewList } from "@/api/common"
export default {
  components: {
    Header,
    Footer,
    CupSmallStart,
  },
  data() {
    return {
      serviceId: 0,
      service: {},
      value: 5.0,
      options: [
        {
          value: "desc",
          label: "最新发布",
        },
        {
          value: "asc",
          label: "综合排序",
        },
      ],
      selectValue: "",
      dialogVisible: false,
      checked1:false,

      reviewListData: [],
      reviewListPages: {
        page: 1, 
        page_size: 10, 
        total: 0 
      }
    };
  },
  created() {
    this.serviceId = this.$route.params.id;
    this.loadInfoData();
    this.loadReviews( this.reviewListPages )
  },
  methods: {
    async loadInfoData() {
      const res = await getServiceInfo({ id: this.serviceId });
      if (res.statusCode == 200) {
        this.service = res.data;
      }
    },
    async loadReviews( data ){
      const res = await reviewList( data, this.$root.current_token )
      if (res.statusCode == 200) {
        res.data.list.forEach(element => {
          element.score = Number( element.score )
        });
        this.reviewListData = res.data.list;
        this.reviewListPages = { 
          page: Number( res.data.pages.page ), 
          page_size: Number( res.data.pages.page_size ), 
          total: Number( res.data.pages.total ) 
        }
      }
    },
    handleSizeChange(val) {
      this.reviewListPages.page_size = val;
      this.loadReviews(this.reviewListPages);
    },
    handleCurrentChange(val) {
      this.reviewListPages.page = val;
      this.loadReviews(this.reviewListPages);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.service-content{
  font-size: 14px;
  line-height: 20px;
  color: #575757;
  text-indent:25px
}
.task-detail {
  background-color: #f8f8f8;
}
.task-detail-center {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  .task-detail-center-header {
    font-size: 16px;
    color: #575757;
    margin-bottom: 20px;
  }
  .task-detail-center-border {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .task-detail-center-border-left {
      width: 895px;
      .task-detail-center-border-left-imgBorder {
        width: 895px;
        height: 571px;
        .task-center-bigimg {
          img {
            width: 895px;
            height: 450px;
          }
        }
        .task-center-smallimg {
          margin-top: 10px;
          width: 895px;
          height: 110px;
          .task-center-smallimg-center {
            width: 915px;
            img {
              width: 163px;
              height: 110px;
              margin-right: 20px;
            }
          }
        }
      }
      .task-detail-center-border-left-fontBorder {
        margin-top: 40px;
        width: 895px;
        min-height: 300px;
        background: #ffffff;
        .task-detail-center-border-left-fontBorder-header {
          line-height: 85px;
          font-size: 24px;
          border-bottom: 1px solid #b5b5b5;
          display: flex;
          justify-content: space-between;
          h2 {
            margin-left: 40px;
          }
        }
        .task-detail-center-border-left-fontBorder-header-center {
          width: 815px;
          margin: 0 auto;
          .task-detail-center-project-detail {
            margin-top: 20px;
            min-height: 150px;
            border-bottom: 1px solid #b5b5b5;
          }
          .task-project-detail-name {
            margin-bottom: 20px;
          }
          .task-project-detail-ul {
            font-size: 12px;
            span {
              display: block;
              margin-bottom: 12px;
            }
            .task-project-detail-ul1 {
              margin-bottom: 45px;
              li {
                margin-bottom: 12px;
              }
            }
          }
          .task-project-detail-other {
            span {
              font-size: 12px;
              display: block;
              margin-bottom: 12px;
            }
          }
        }
        .task-detail-center-border-left-fontBorder-bottom {
          width: 815px;
          margin: 0 auto;
          .task-detail-center-border-left-fontBorder-bottom-header {
            h2 {
              margin-top: 20px;
              font-size: 18px;
            }
          }
          .task-serve {
            margin-top: 20px;
            font-size: 12px;
            li {
              margin-bottom: 10px;
              strong {
                font-weight: 540;
              }
            }
          }
        }
      }
      .task-detail-center-border-left-serveBorder {
        width: 895px;
        height: 689px;
        background: #ffffff;
        margin-top: 20px;
        .task-serve-header {
          height: 79px;
          border-bottom: 1px solid #b5b5b5;
          .task-serve-header-center {
            width: 815px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            height: 26px;
            position: relative;
            top: 42px;
            .task-serve-header-center-left {
              line-height: 26px;
            }
            .task-serve-header-center-right {
              display: flex;
              font-size: 12px;
              color: #575757;
              line-height: 26px;
              .task-serve-header-center-right2 {
                margin-left: 20px;
                /deep/.el-input__inner {
                  height: 26px;
                  width: 150px;
                }
                /deep/.el-select__caret {
                  position: relative;
                  top: 5px;
                }
              }
            }
          }
        }
        .task-serve-centers {
          border-bottom: 1px solid #b5b5b5;
          .task-serve-center-border {
            margin: 20px 40px;
            display: flex;

            .task-serve-center-left {
              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
            }
            .task-serve-center-right {
              margin-left: 20px;
              .task-serve-center-right-top {
                display: flex;
                height: 18px;
                width: 755px;
                justify-content: space-between;
                .task-serve-center-right-top1 {
                  strong {
                    font-size: 16px;
                  }
                  span {
                    margin-left: 40px;
                    font-size: 12px;
                    color: #575757;
                  }
                }
                /deep/.el-rate__icon {
                  margin-right: 0;
                  font-size: 12px;
                }
              }
              .task-serve-center-right-bottom {
                margin-top: 12px;
                font-size: 12px;
              }
            }
          }
        }
      }
      .task-serve-recommend {
        margin-top: 20px;
        .task-serve-recommend-header {
          width: 895px;
          height: 50px;
          background: #ffffff;
          display: flex;
          justify-content: space-between;
          .task-serve-recommend-header-left {
            margin-left: 10px;
            line-height: 50px;
            span {
              font-size: 18px;
            }
          }
          .task-serve-recommend-header-right {
            cursor: pointer;
            margin-right: 10px;
            line-height: 50px;
            display: flex;
            img {
              width: 16px;
              height: 16px;
              position: relative;
              top: 15px;
              margin-right: 10px;
            }
            strong {
              font-size: 12px;
              color: #575757;
            }
          }
        }
        .task-serve-recommend-bottom {
          margin-top: 20px;
          width: 895px;
          .task-serve-recommend-bottom-center {
            width: 910px;
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }
    .task-detail-center-border-right {
      width: 285px;
      height: 200px;
      .task-detail-center-border-right1 {
        width: 285px;
        line-height: 60px;
        background: #ffffff;
        span {
          font-size: 18px;
          color: #575757;
          margin-left: 80px;
        }
        img {
          width: 24px;
          height: 24px;
          position: relative;
          top: 4px;
          margin-left: 20px;
        }
      }
      .task-detail-center-border-right2 {
        margin-top: 20px;
        width: 285px;
        height: 359px;
        background: #ffffff;
        .task-detail-center-border-right2-header {
          height: 80px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #b5b5b5;
          .task-detail-center-border-right2-header-photo {
            margin-left: 20px;
            margin-right: 10px;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }
          .task-detail-center-border-right2-header-font {
            h2 {
              margin-bottom: 5px;
              font-size: 16px;
            }
            p {
              span {
                font-size: 12px;
                color: #575757;
                margin-right: 10px;
              }
              button {
                width: 70px;
                height: 16px;
                background: #00a2e6;
                border-radius: 8px;
                font-size: 12px;
                color: #fff;
              }
            }
          }
        }
        .task-detail-center-border-right2-bottom {
          span {
            font-size: 12px;
            color: #000;
          }
          b {
            font-size: 12px;
            color: #575757;
          }
          .task-detail-center-border-right2-bottom1 {
            margin-top: 20px;
            margin-left: 20px;
            display: flex;
            font-size: 12px;

            .task-detail-center-border-right2-bottom-left {
              margin-right: 27px;
              strong {
                color: #00a2e6;
              }
            }
          }
          .task-detail-center-border-right2-bottom2 {
            margin: 20px 0 0 20px;
          }
          .task-detail-center-border-right2-bottom3 {
            margin-top: 10px;
            margin-left: 20px;
            /deep/.el-rate__icon {
              margin-right: 0;
              font-size: 12px;
            }
          }
          .task-detail-center-border-right2-bottom4 {
            margin-top: 20px;
            margin-left: 20px;
            font-size: 12px;
            line-height: 15px;
            color: #575757;
            margin-bottom: 10px;
            button {
              color: #00a2e6;
              background-color: #fff;
            }
            ul {
              li {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
      .task-detail-center-border-right3 {
        margin-top: 20px;
        width: 285px;
        height: 264px;
        background: #37373c;
        .task-detail-center-border-right3-center {
          width: 245px;
          margin: 0 auto;
          border-bottom: 1px solid #b5b5b5;
          .task-detail-center-border-right3-center1 {
            height: 110px;
            width: 140px;
            margin: 0 auto;
            color: #fff;
            .task-detail-center-border-right3-center1-font1 {
              padding-top: 40px;
              font-size: 18px;
            }
            .task-detail-center-border-right3-center1-font2 {
              margin-top: 20px;
              font-size: 14px;
            }
          }
        }
        .task-detail-center-border-right3-center-button {
          width: 200px;
          margin: 0 auto;
          display: flex;
          .task-detail-center-border-right3-center-button-center {
            button {
              width: 200px;
              height: 36px;
            }
            .task-detail-center-border-right3-center-button1 {
              button {
                margin-top: 20px;
                background-color: #00a2e6;
                font-size: 18px;
                color: #fff;
              }
            }
            .task-detail-center-border-right3-center-button2 {
              margin-top: 20px;
              line-height: 32px;
              button {
                font-size: 18px;
                background-color: transparent;
                border-radius: 0;
                color: #fff;
                /deep/ span {
                  position: relative;
                  top: -3px;
                }
              }
              /deep/.el-dialog__header {
                line-height: 42px;
                border-bottom: 1px solid #b5b5b5;
              }
              .el-dialog-checked {
                /deep/.el-dialog-first {
                  margin-left: 20px;
                  margin-bottom: 20px;
                  display: flex;
                  height:20px;
                  align-items: center;
                  .el-checkbox__inner{
                    width: 12px;
                    height: 12px;
                    position: relative;
                    top: -5px;
                  }
                  .el-checkbox__input.is-checked .el-checkbox__inner,
                  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                    background-color: #00a2e6;
                    border-color: #00a2e6;
                  }
                  .el-checkbox__label{
                    width: 347px;
                    overflow: hidden;
                    white-space: nowrap; 
                    text-overflow: ellipsis;
                    color: #000;
                    -o-text-overflow:ellipsis;
                  }
                  .el-dialog-first-left{
                    width: 420px;
                    line-height: 20px;
                    border-right: 1px solid #b5b5b5;
                  }
                  .el-dialog-first-center{
                    width: 149px;
                    border-right: 1px solid #b5b5b5;
                    text-align: center;
                    strong{
                      font-size: 14px;
                      color: #e4007f;
                    }
                  }
                  .el-dialog-first-right{
                    margin-left: 50px;
                    b{
                      color: #575757;
                    }
                  }
                  
                }
              }
            }
          }
        }
      }
      .task-detail-center-border-right4 {
        margin-top: 20px;
        width: 285px;
        height: 294px;
        background: #ffffff;
        .task-detail-center-border-righ4-header {
          line-height: 57px;
          border-bottom: 1px solid #b5b5b5;
          h2 {
            margin-left: 20px;
          }
        }
        .task-detail-center-border-right4-bottom {
          ul {
            margin-top: 20px;
            margin-left: 30px;
            li {
              line-height: 16px;
              margin-bottom: 20px;
              display: flex;
              img {
                display: block;
                width: 16px;
                height: 16px;
                margin-right: 20px;
              }
              span {
                font-size: 12px;
                color: #575757;
              }
            }
          }
        }
      }
    }
  }
}

/deep/.el-rate {
  display: inline-block;
}
/deep/.el-rate {
  margin-right: 0;
}
</style>
