<template>
  <div>
    <el-popover :trigger="trigger">
      <div class="popover_content">
        <ul class="popover_main">
          <li><p>任务</p></li>
          <li @click="$router.push('/home/hirer/task/list')">
            <span>我发布的任务</span>
          </li>
          <li @click="$router.push('/home/freelancer/task/list')">
            <span>我投标的项目</span>
          </li>
<!--          <li><span>评价</span></li>-->
          <hr/>
          <li><p>账户</p></li>
          <li @click="$router.push('/home/account/overview')">
            <span>账户总览</span>
          </li>
          <li @click="$router.push('/home/account/recharge')">
            <span>充值</span>
          </li>
          <li @click="$router.push('/home/account/overview')">
            <span>提现</span>
          </li>
          <hr/>
          <li @click="$router.push('/home/page')">个人主页</li>
          <li @click="$router.push('/home/information/base')">
            <span>基本资料</span>
          </li>
        </ul>
        <div class="popover_out">
<!--        class="el-icon-switch-button"   -->
          <el-link :underline="false" type="text" style="font-size:14px;" class="h-57" @click="logOut">
            退出登录
          </el-link>
        </div>
      </div>
      <div slot="reference">
        <img v-lazy="userinfo.avatar" @click="$router.push('/home/page')" class="popover-img"/>
      </div>
    </el-popover>

  </div>

</template>

<script>
export default {
  name: "HeaderPopover",
  props : {
    userinfo : {},
    trigger : {
      default : 'hover'
    },
  },
  data(){
    return{

    }
  },
  mounted() {

  },
  methods : {
    logOut(){
      this.$loginSetting.toLogout()
    }
  }
}
</script>

<style scoped>
hr {
  height: 1px;
  background-color: #f4f4f4;
  border: none;
}

.popover_content .popover_main{
  width: 180px;
  /*height:295px;*/
  /*overflow-y:scroll;*/
}
.popover_content li{
  margin-left: 10px;
  cursor: pointer;
  line-height: 34.5px;
}
.popover_content li p {
  font-size: 16px;
  cursor: pointer;
}
.popover_content li span {
  color: #575757;
  font-size: 12px;
  cursor: pointer;
}
.popover-img{
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}
.popover_out{
  border-top: 1px solid #f4f4f4;
  padding:12px 12px 0 12px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  margin-left:-12px;
  margin-right:-12px;
}
</style>
