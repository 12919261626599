<template>
  <!-- 底部 -->
  <footer>
    <div class="shop-footer">
      <!-- 内部小盒子 -->
      <div class="shop-footer-center clear-fix">
        <!-- 分为左边 -->
        <div class="shop-footer-center-left">
          <!-- 上半部分 -->
          <div class="shop-footer-center-left-top">
            <ul class="shop-footer-center-about">
              <li><a href="http://www.gogowork.cn/help/info/6253db653e5476238bc23eb22681f53c" target="_bank">服务条款</a></li>
              <li><a href="http://www.gogowork.cn/help/info/6253d217d47f4539c7b42b85af60cfdc" target="_bank">隐私协议</a></li>
              <li><a href="http://www.gogowork.cn/help/info/62544425ade1e93cc835be303f996657" target="_bank">帮助中心</a></li>
              <li><a href="http://www.gogowork.cn/help/info/62544425ade1e93cc835be303f996657" target="_bank">关于我们</a></li>
              <li><a href="http://www.gogowork.cn/help/info/62544425ade1e93cc835be303f996657" target="_bank">知识园地</a></li>
            </ul>
          </div>
          <!-- 上半部分结束 -->

          <!-- 下半部分 -->
          <div class="shop-footer-center-city">
            <p>公司全称：北京联众智赢科技有限公司</p>
            <span>
              八爪灵工 &nbsp;&nbsp;
              京IPC备：2021019219号-1
            </span>
          </div>
        </div>
        <!-- 中间 -->
        <div class="shop-footer-center-center">
          <div class="shop-footer-center-phone">
            <p><span>联系方式</span></p>
            <ul>
              <li>联系电话：010-64575166</li>
              <li>联系地址：北京市顺义区绿地启航国际</li>
              <li>咨询邮箱：service@gogowork.cn</li>
            </ul>
          </div>
        </div>
        <!-- 右边 -->
        <div class="shop-footer-center-right">
          <div class="shop-footer-center-right-all">
            <p class="pFont">关注我们</p>
            <div class="shop-footer-center-right-img">
              <!-- 官方微信 -->
              <div class="shop-footer-center-right-img-weixin">
                <img src="@/assets/wechat.jpg" alt="" />
                <p><span>官方微信</span></p>
              </div>
              <!-- 官方微博 -->
              <!-- <div class="shop-footer-center-right-img-weibo">
                <img src="@/assets/banner/weibo.png" alt="" />
                <p><span>官方微博</span></p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="less" scoped>
footer,
.shop-footer {
  width: 100%;
  background-color: #00a2e6;
  color: #ffffff;
  height: 280px;
  margin-top: 60px;
  .shop-footer-center {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    /* 距离上面是60px */
    position: relative;
    top: 70px;
    /* 左边 */
    .shop-footer-center-left {
      width: 527px;
      height: 130px;
      border-right: 1px solid #fff;
      .shop-footer-center-left-top {
        width: 432px;
        font-size: 14px;
        .shop-footer-center-about {
          display: flex;
          justify-content: space-between;
          li{
            a{
              color: #fff;
            }
          }
        }
      }
      .shop-footer-center-city {
        margin-top: 30px;
        width: 311px;
        height: 56px;
        font-size: 12px;
        line-height: 22px;
        p {
            font-size: 12px;
          }
      }
    }
    /* 中间 */
    .shop-footer-center-center {
      width: 401px;
      height: 130px;
      border-right: 1px solid #fff;
      .shop-footer-center-phone {
        width: 213px;
        margin: 0 auto;
        p {
          margin-bottom: 30px;
          span {
            font-size: 14px;
          }
        }
        ul {
          width: 249px;
          height: 66px;
          font-size: 12px;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }
    /* 右边 */
    .shop-footer-center-right {
      .shop-footer-center-right-all {
        margin-left: 88px;
        .pFont {
          font-size: 14px;
        }
        p {
          margin-bottom: 30px;
        }
      }
      .shop-footer-center-right-img {
        display: flex;
        justify-content: space-between;
        img {
          width: 60px;
          height: 60px;
        }
        p {
          margin-top: 10px;
          text-align: center;
          span{
            font-size: 12px;
          }
        }
        .shop-footer-center-right-img-weibo {
          position: relative;
          right: -56px;
        }
      }
    }
  }
}
</style>
