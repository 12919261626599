<template>
  <header>
    <!-- 中间部分 -->
    <div class="header-main">
      <!-- 左边部分 -->
      <div class="header-left">
        <ul>
          <li class="l-first">
            <a href="javascript:void(0);" @click="$router.push('/')"
            ><img src="@/icons/svg/shop/logo-108X24.svg"
            /></a>
          </li>
          <li class="l-second">
            <a href="javascript:void(0);" @click="$router.push('/')">首页</a>
          </li>
          <li class="l-second">
            <a href="javascript:void(0);" @click="toPage('/task')">任务大厅</a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="toPage('/freelancer')">专业人才</a>
          </li>
          <!-- <li>
            <a href="javascript:void(0);" @click="toPage('/service')"
            >技能服务</a
            >
          </li> -->
          <li>
            <a href="javascript:void(0);" @click="toPage('/business')">业务介绍</a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="toPage('/product')">产品服务</a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="toPage('/task/add')">发布任务</a>
          </li>
        </ul>
      </div>
      <!-- 右边部分 -->
      <div class="header-right">
        <!-- 未登录状态 -->
        <ul class="header-login" v-if="!isLogin&&!$loginSetting.isLogin()">
          <li class="cp"><a @click="toLogin">登录</a></li>
          <li><a href="#">&nbsp;/&nbsp;</a></li>
          <li class="cp"><a @click="toRegister">注册</a></li>
        </ul>

        <!-- 登录成功之后  显示无信息 icon-->
        <ul v-if="isLogin||$loginSetting.isLogin()" class="pop">
          <!-- 显示通知 -->
          <!--  <li>
             <a @click="toNotify" style="cursor: pointer"
             ><img
                 src="@/icons/svg/shop/提醒24X24.svg"
                 alt=""
             />
               <img src="@/icons/svg/shop/information16X16.svg" alt=""/>
             </a>
           </li> -->
          <!-- 显示通知消息 -->
          <li class="header-x">
            <!-- 通知 -->
            <!--            <span>-->
            <!--              <svg class="icon cu" aria-hidden="true">-->
            <!--              <use xlink:href="#icon-remind16X16"></use>-->

            <!--            </svg>-->
            <!--            通知-->
            <!--            </span>-->

            <!-- 消息 -->
            <!--            <span>-->
            <!--              <svg class="icon cu" aria-hidden="true">-->
            <!--              <use xlink:href="#icon-information"></use>-->
            <!--            </svg>-->
            <!--            消息-->
            <!--            </span>-->

          </li>
          <headerNotfication/>
          <header-popover v-if="isLogin||$loginSetting.isLogin()" :userinfo="userinfo" @logout="logout"/>
        </ul>
      </div>
    </div>
    <div class="bz_header">
      <div class="navbar-toggle ">
        <div style="display:flex;justify-content: space-between">
          <div class="l-first">
            <a href="javascript:void(0);" @click="$router.push('/')"><img src="@/icons/svg/shop/logo-108X24.svg"/></a>
          </div>
          <div class="u-flex">
            <header-popover v-if="isLogin||$loginSetting.isLogin()" :userinfo="userinfo" style="margin-right:10px;" trigger="click"/>
            <span @click="onIsShowNav"><img src="@/assets/navbar.svg" width="30" height="30"/></span>
          </div>

        </div>
      </div>
      <div v-show="isShowNav" id="navbarcontent" class="u-flex u-flex u-flex-1">
        <div class="nav u-flex-1 u-flex">
          <div class="navtitle curspointer">
            <a href="javascript:void(0);" @click="$router.push('/')">首页</a>
          </div>
          <div class="navtitle curspointer">
            <a href="javascript:void(0);" @click="toPage('/task')">任务大厅</a>
          </div>
          <div class="navtitle curspointer">
            <a href="javascript:void(0);" @click="toPage('/freelancer')">专业人才</a>
          </div>
          <div class="navtitle curspointer">
            <a href="javascript:void(0);" @click="toPage('/business')">业务介绍</a>
          </div>
          <div class="navtitle curspointer">
            <a href="javascript:void(0);" @click="toPage('/product')">产品服务</a>
          </div>
          <div class="navtitle curspointer">
            <a href="javascript:void(0);" @click="toPage('/task/add')">发布任务</a>
          </div>
        </div>
        <div class="header-right" v-show="!isLogin&&!$loginSetting.isLogin()">
			<span class="button curspointer" @click="toLogin">
              登录
            </span>
          <span class="button active curspointer" @toRegister="toRegister">
            注册
          </span>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import {getUserInfo,online} from "@/api/user";
import headerPopover from '@/components/Header/popover.vue'
import headerNotfication from "@/components/Notfication"

export default {
  name: "Header",
  computed: {
    isLogin(){
      return this.$store.state.useUserStore.isLogin
    },
    isUpdateUserInfo() {
      return this.$store.state.useUserStore.userInfo
    }
  },
  watch: {
    isLogin( newv ){
      if( newv ){
        this.startSyncOnline()
        this.userinfo = this.$store.state.useUserStore.userInfo
      }
    },
    isUpdateUserInfo( newv ){
      if( newv ){
        this.startSyncOnline()
        this.userinfo = this.$store.state.useUserStore.userInfo
      }
    }
  },
  components: {headerPopover, headerNotfication},
  data() {
    return {
      userinfo: {avatar: ""},
      activeIndex: "1",
      isShowNav: false, //是否显示成手机端下拉
      onlineTimer:null,//在线统计
      onlinePerMicSeconds: 300000, //5分钟同步一次
    }
  },
  created() {
    if (this.$loginSetting.isLogin()) {
      this.startSyncOnline()
      this.userinfo = this.$loginSetting.getUserInfo();
    }
  },
  beforeDestroy() {
    if (this.onlineTimer != null) {
      clearInterval(this.onlineTimer);
    }
  },
  methods: {
    startSyncOnline(){
      this.syncOnline();
      this.onlineTimer = setInterval(this.syncOnline, this.onlinePerMicSeconds);
    },
    stopSyncOnline(){
      if (this.onlineTimer != null) {
        clearInterval(this.onlineTimer);
      }
    },
    syncOnline(){
      online({}).then(res=>{

      })
    },
    onIsShowNav() {
      this.isShowNav = !this.isShowNav;

      console.log( '------onIsShowNav------');
      console.log( this.isShowNav )
    },

    async loadUserInfo() {
      const res = await getUserInfo();
      if (res.statusCode === 200) {
        this.userinfo = res.data;
      }
    },
    logout(){
      console.log( '---logout--')
      this.stopSyncOnline()
    },
    /* 显示弹框 */
    showPoput(type) {
      this.$refs[type].style = "display: block";
    },
    /* 隐藏弹框 */
    closePoput(type) {
      this.$refs[type].style = "display: none";
    },
    // 去登录页
    toLogin() {
      this.$redirectUrl.jumpTo( '/login' );
    },
    // 去注册页
    toRegister() {
      this.$router.push({path: "/register"});
    },
    // 通知
    toNotify() {
      this.$router.push({path: "/notify"});
    },
    // 消息
    toMsg() {
      this.$router.push({path: "/msg"});
    },
    toPage(url) {
      this.$router.push({path: url});
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="less">


header {
  top: 0px;
  position: sticky;
  height: 64px;
  background: #ffffff;
  z-index: 88;

  .header-main {
    margin: 0 auto;
    width: 1200px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li,
    a {
      font-size: 16px;
      line-height: 36px;
    }
  }

  .header-left {
    a {
      color: #000000;
    }

    ul {
      .l-first {
        display: flex;
        align-items: center;
        margin-left: 0;

        img {
          width: 108px;
          height: 24px;
          display: inline;
          position: relative;
          top: 5px;
        }
      }

      li {
        margin-left: 40px;
      }
    }
  }

  .header-right {
    /* 登录成功之后显示的头像等 */

    ul {
      position: relative;

      li {
        a {
          img {
            display: block;
            width: 16px;
            height: 16px;
          }
        }
      }

      .header-photo {
        display: block;
        padding-left: 40px;

        img {
          display: inline-block;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          position: relative;
          top: 5px;
          cursor: pointer;
          object-fit: cover;
        }
      }

      .header-photo-popout {
        display: none;
        width: 160px;
        height: 345px;
        background: #ffffff;
        position: absolute;
        top: 61px;
        left: -80px;
        box-shadow: 0px 0px 16px 0px #cccccc;

        .header-photo-popout-three {
          width: 0;
          height: 0;
          border-top: 15px solid transparent;
          border-right: 15px solid #fff;
          border-bottom: 0px solid transparent; /*1.底边去除就变成直角三角形*/
          border-left: 15px solid transparent;
          position: relative;
          top: -15px;
          left: 100px;
        }

        .header-photo-popout-three::before {
          width: 160px;
          height: 30px;
          display: inline-block;
          content: "";
          position: relative;
          top: -20px;
          left: -115px;
        }

        /*  .header-photo-popout-three::after{
           width: 30px;
           height: 30px;
           display: inline-block;
           content: "";
           position: relative;
           top: -100px;
           left: -40px;
           border: 1px solid red;
         } */

        .header-photo-popout-center {
          .header-photo-popout-center-top {
            overflow: auto;
            width: 120px;
            height: 277px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .header-photo-popout-center-top-left {
              width: 100px;
              height: 100%;

              p {
                font-size: 16px;
                cursor: pointer;
              }

              span {
                color: #575757;
                font-size: 12px;
                cursor: pointer;
              }

              display: block;

              li {
                cursor: pointer;
                line-height: 34.5px;
              }
            }

            .header-photo-popout-center-top-right {
              width: 8px;
              height: 100px;
              background: #b5b5b5;
              border-radius: 4px;
            }
          }

          .header-photo-popout-center-bottom {
            border-top: 1px solid #b5b5b5;
            margin-top: 10px;
            width: 160px;
            height: 50px;

            p {
              cursor: pointer;
              font-size: 16px;
              margin-left: 20px;
            }
          }
        }
      }

      .header-x {

      }
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header-login li a {
    color: #00a2e6;
  }

  .cp {
    cursor: pointer;
  }
}

/*定义了滚动条整体样式；*/
::-webkit-scrollbar {
  //height: 30px !important;
  //width: 6px !important;
}

::-webkit-scrollbar-button {
  height: 10px;
}

/*定义了滚动条滑块的样式*/
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /*   border-style: dashed; */
  background-color: #dcdddd;
  /*  border-color: #e2242400; */
  border-width: 1.5px;
  /* background-clip: padding-box; */
}

/*定义了轨道的样式*/

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /*  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  /* border-radius: 10px;
  background: #ededed; */
  position: relative;
  right: -6px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(157, 165, 183, 0.7);
}

/deep/ .el-icon-arrow-down:before {
  content: "";
}

/deep/ .el-menu.el-menu--horizontal {
  border-bottom: none;
}

/deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none;
}


.u-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-flex-nowrap {
  flex-wrap: nowrap;
}

.u-flex-1 {
  flex: 1;
}


#navbarcontent {
  padding-bottom: 30px;
  margin-top: 30px;
  flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-grow: 1;
  flex-direction: column;
  -ms-flex-direction: column;
  animation: header-fade-up 0.3s ease-out;
  -webkit-animation: header-fade-up .3s;
}

#navbarcontent .nav {
  flex-direction: column;
  -ms-flex-direction: column;
  width: 100%;
  padding: 0px 40px;
}

#navbarcontent .nav .navtitle {
  width: 100%;
  margin: 0px;
  padding: 15px 0px;
  text-align: center;
  border-bottom: 1px dotted #cdcdcd;
}

#navbarcontent .header-right {
  flex-direction: column;
  -ms-flex-direction: column;
  display: flex;
}

#navbarcontent .header-right .button {
  margin: 5px 0px;
}

//#navbarcontent:not(.show) {
//  display: none; }


.bz_header {
  display: none;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bz_header .navbar-toggle .l-first img {
  width: 108px;
  height: 24px;
  display: inline;
  position: relative;
  top: 5px;
}

.bz_header .navbar-toggle .l-first a {
  font-size: 16px;
  line-height: 36px;
}

@media screen and (max-width: 1100px) {
  .bz_footer .bz_wrapper {
    width: 100%;
    flex-wrap: wrap;
  }

  .bz_footer {
    padding: 20px;
  }

  .bz_footer .footertitle {
    display: flex;
    flex-wrap: wrap;
  }

  .bz_footer .footertitle span:nth-of-type(1) {
    margin: 0px;
  }

  .bz_footer .footertitle span {
    margin: 0px;
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 992px) {
  @keyframes header-fade-up {
    0% {
      transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
    }
    100% {
      transform: translate(0);
      -webkit-transform: translate(0);
      transition: transform 0.3s ease-out;
      -webkit-transition: transform 0.3s ease-out;
    }
  }
  @-webkit-keyframes header-fade-up {
    0% {
      transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
    }
    100% {
      transform: translate(0);
      -webkit-transform: translate(0);
      transition: transform 0.3s ease-out;
      -webkit-transition: transform 0.3s ease-out;
    }
  }
  .bz_header .navbar-toggle {
    display: block;
    background: #fff;
  }

  .bz_header {
    display: block;
  }

  .header-main {
    display: none !important;
  }

  #navbarcontent {
    background: #fff;
  }

  .bz_header .header-right {
    padding-right: 0px;
  }

  .bz_header_wrapper {
    background-color: white;
    box-shadow: 0 1px 3px #cdcdcd;
  }

  .bz_header_hoder {
    background-color: white;
  }

  .bz_header_hoder {
    background: transparent;
  }

  /*#navbarcontent {*/
  /*  padding-bottom: 30px;*/
  /*  margin-top: 30px;*/
  /*  flex-basis: 100%;*/
  /*  -ms-flex-preferred-size: 100%;*/
  /*  flex-grow: 1;*/
  /*  flex-direction: column;*/
  /*  -ms-flex-direction: column;*/
  /*  animation: header-fade-up 0.3s ease-out;*/
  /*  -webkit-animation: header-fade-up .3s; }*/
  /*#navbarcontent .nav {*/
  /*  flex-direction: column;*/
  /*  -ms-flex-direction: column;*/
  /*  width: 100%;*/
  /*  padding: 0px 40px; }*/
  /*#navbarcontent .nav .navtitle {*/
  /*  width: 100%;*/
  /*  margin: 0px;*/
  /*  padding: 15px 0px;*/
  /*  text-align: center;*/
  /*  border-bottom: 1px dotted #cdcdcd; }*/
  /*#navbarcontent .header-right {*/
  /*  flex-direction: column;*/
  /*  -ms-flex-direction: column;*/
  /*  display: flex; }*/
  /*#navbarcontent .header-right .button {*/
  /*  margin: 5px 0px; }*/
  /*#navbarcontent:not(.show) {*/
  /*  display: none; }*/
  .bz_footer .footertitle span {
    margin: 0px;
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 768px) {
  .bz_header {
    display: block;
  }

  .bz_footer .footerleft, .bz_footer .footeright {
    flex: 0 0 100%;
  }

  .bz_footer .footertitle span {
    flex: 0px;
  }

  .footerpad-r-70::after {
    border-left: 0px;
  }
}

@media screen and (max-width: 576px) {
  .bz_footer .footertitle span {
    flex: 0 0 100%;
  }

  .bz_footer .footerleft, .bz_footer .footeright .footerpad-r-70 {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .bz_footer .bz_wrapper {
    position: relative;
  }

  .bz_footer .footerleft::before, .bz_footer .footeright .footerpad-r-70::before {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    content: "";
    display: inline-block;
    border-bottom: solid 1px #ffffff;
    height: 1px;
    width: 100%;
    transform: scaleY(0.5);
  }

  .bz_footer .footertitle span::before {
    content: "";
    width: 10px;
    height: 10px;
    border: solid 1px #ffffff;
    display: inline-block;
    border-top: 0px;
    border-right: 0px;
    transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
  }
}

@media screen and (max-width: 416px) {
  .bz_header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logo img {
    height: 28px;
  }

  .bz_header_hoder img {
    height: 28px;
  }

  .footerpad-r-70 {
    padding-right: 0px;
  }
}

</style>
