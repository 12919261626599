<template>
  <div>
    <el-popover :trigger="trigger" v-if="false">
      <div class="popover_content">
        <div class="popover_content_header">
          <div class="popover_content_header_center">
            <h2>通知</h2>
            <span class="cu">清空</span>
          </div>
        </div>
        <div class="popover_content_header_center_item">
          <ul>
            <li>[审核] 灵工助手 发来了1个通知</li>
            <li>[审核] 灵工助手 发来了1个通知</li>
            <li>[审核] 灵工助手 发来了1个通知</li>
            <li>[审核] 灵工助手 发来了1个通知</li>
            <li>[审核] 灵工助手 发来了1个通知</li>
          </ul>
        </div>
        <div class="popover_out" style="text-align: center" >
          <el-button type="text">
            查看更多
          </el-button>
        </div>
      </div>
      <div slot="reference" class="popover_bottom">
        <span>
          <svg class="icon cu" aria-hidden="true">
          <use xlink:href="#icon-remind16X16"></use>
          </svg>
            通知
        </span>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "Notfication",
  props : {
    trigger : {
      default : 'hover'
    },
  },

}
</script>

<style scoped lang="less">
.popover_content{
  width: 200px;
  .popover_content_header{
    line-heigt:60px;
    border-bottom: 1px solid #ddd;
    .popover_content_header_center{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .popover_content_header_center_item{
    ul{
      height: 200px;
      overflow-y: scroll;
      li{
        line-height: 46px;
        border-bottom: 1px solid #ddd;
        font-size: 12px;
        color: #575757;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}
span{
  display: inline-block;
  line-height: 32px;
svg{
  font-size: 16px;
  margin-right: 10px;
}
font-size: 12px !important;
margin-right: 30px;
}

</style>